<template>
  <div class="p-4">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'Questions'
            }"
          >
            {{ $t("pageTitle.Question") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h3>{{ pageTitle }}</h3>
    <hr class="separate-line" />
    <div class="search">
      <el-form ref="form" label-width="80px">
        <el-form-item label="Type">
          <el-select v-model="activeType">
            <el-option
              v-for="item in typeOptions"
              :key="item.obj"
              :label="item.name"
              :value="item.obj"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="(subject, index) in subjectArr"
          :key="index"
          v-show="activeType === subject.name"
        >
          <el-radio-group v-model="questionType">
            <el-radio-button
              :key="toeflSubject.id"
              v-for="toeflSubject in subject.subjects"
              :label="toeflSubject.id"
            >
              {{ toeflSubject.title }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <hr class="separate-line" />
    </div>
    <template>
      <el-form label-width="100px" ref="form" :model="question">
        <div style="padding:0 20px;border: 1px solid #dcdfe6;">
          <el-row>
            <el-col :span="12" style="height:700px;overflow:auto;" class="p-3">
              <h4>Passage</h4>
              <hr />
              <div style="padding-right:20px">
                <div style="text-align: center; margin-bottom: 20px">
                  請問這個題目需要 Passage 嗎？
                  <el-button
                    style="margin: 0px 4px"
                    :type="
                      howToHandlePassages === 'doNotNeedPassage'
                        ? 'primary'
                        : ''
                    "
                    @click="changePassageType('doNotNeedPassage')"
                  >
                    不需要
                  </el-button>
                  <el-button
                    style="margin: 0px 4px"
                    :type="
                      howToHandlePassages === 'chooseExistPassage'
                        ? 'primary'
                        : ''
                    "
                    @click="changePassageType('chooseExistPassage')"
                  >
                    需要，直接選擇現有的 Passage
                  </el-button>
                  <el-button
                    style="margin: 0px 4px"
                    :type="
                      howToHandlePassages === 'createNewPassage'
                        ? 'primary'
                        : ''
                    "
                    @click="changePassageType('createNewPassage')"
                  >
                    需要，並新增一個新的 Passage
                  </el-button>
                </div>
                <el-form-item
                  v-if="howToHandlePassages === 'chooseExistPassage'"
                  label="Passage"
                >
                  <el-select
                    v-model="question.passage_id"
                    filterable
                    placeholder="Select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="passage in passages"
                      :key="passage.id"
                      :value="passage.id"
                      :label="convertHtmlToText(passage.content)"
                    >
                      <div v-html="passage.content" />
                    </el-option>
                  </el-select>
                </el-form-item>
                <div v-if="howToHandlePassages === 'createNewPassage'">
                  <PassageForm
                    :passage="passage"
                    @setPassage="setPassage"
                    :testType="$route.params.testType"
                    :sectionName="sectionName"
                  />
                </div>
                <ViewPassage
                  :key="question"
                  :passage="getCurrenctPassage()"
                  :type="$route.params.testType"
                  :section="sectionName"
                />
              </div>
            </el-col>
            <el-col class="ivy-question" :span="12">
              <QuestionForm
                :isEditMode="true"
                :question="question"
                :tags="tags"
                @setQuestion="setQuestion"
                :testType="$route.params.testType"
                :sectionName="sectionName"
              />
            </el-col>
          </el-row>
        </div>
        <hr class="separate-line" />
      </el-form>
    </template>
    <div style="text-align: center; margin-bottom: 20px">
      <el-button style="margin: 20px" type="primary" @click="handleQuestion">
        {{ $t("button.save") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { word } from "@ivy-way/material";
import testsApi from "@/apis/tests.js";
import tagApi from "@/apis/tag";
import Breadcrumb from "@/components/Breadcrumb";
import PassageForm from "@/components/tests/PassageForm";
import QuestionForm from "@/components/tests/QuestionForm";
import ViewPassage from "@/components/tests/ViewPassage.vue";

export default {
  metaInfo() {
    return {
      title: "Edit Question - Ivy-Way Academy"
    };
  },
  components: {
    Breadcrumb,
    PassageForm,
    QuestionForm,
    ViewPassage
  },
  computed: {
    actSubjects() {
      return [
        { id: "act-english", title: "English" },
        { id: "act-math", title: "Math" },
        { id: "act-reading", title: "Reading" },
        { id: "act-science", title: "Science" }
      ];
    },
    subjectArr() {
      return [
        {
          name: "satSubjects",
          subjects: [
            { id: "sat-reading", title: "Reading" },
            { id: "sat-writing", title: "Writing" },
            { id: "sat-math", title: "Math" },
            { id: "sat-math_calculator", title: "Math Calculator" }
          ]
        },
        {
          name: "newSatSubjects",
          subjects: [
            { id: "sat-English", title: "English" },
            { id: "sat-math", title: "Math" }
          ]
        },
        {
          name: "toeflSubjects",
          subjects: [{ id: "toefl-reading", title: "Reading" }]
        },
        {
          name: "actSubjects",
          subjects: [
            { id: "act-english", title: "English" },
            { id: "act-math", title: "Math" },
            { id: "act-reading", title: "Reading" },
            { id: "act-science", title: "Science" }
          ]
        }
      ];
    },
    satSubjects() {
      return [
        { id: "sat-reading", title: "Reading" },
        { id: "sat-writing", title: "Writing" },
        { id: "sat-math", title: "Math" },
        { id: "sat-math_calculator", title: "Math Calculator" }
      ];
    },
    newSatSubjects() {
      return [
        { id: "sat-English", title: "English" },
        { id: "sat-math", title: "Math" }
      ];
    },
    toeflSubjects() {
      return [{ id: "toefl-reading", title: "Reading" }];
    },
    examTagTypes() {
      return [
        { name: "Gammar", key: "question_grammar" },
        { name: "Math", key: "question_math" },
        { name: "Reading", key: "question_reading" },
        { name: "Science", key: "question_science" }
      ];
    },
    word() {
      return word;
    },
    isEditMode() {
      return this.$route.name === "EditQuestion";
    },
    pageTitle() {
      return `${this.isEditMode ? "Edit" : "Add"} ${this.$t(
        "pageTitle.Question"
      )}`;
    },
    isShowPassage() {
      return (
        this.howToHandlePassages === "chooseExistPassage" &&
        this.question.passage_id
      );
    }
  },
  data() {
    return {
      questionType: "",
      activeType: "toeflSubjects",
      typeOptions: [
        {
          name: "TOEFL",
          obj: "toeflSubjects"
        },
        {
          name: "ACT",
          obj: "actSubjects"
        },
        {
          name: "New Digital SAT",
          obj: "newSatSubjects"
        },
        {
          name: "Old Paper-Based SAT",
          obj: "satSubjects"
        }
      ],
      search: {
        test_types: [],
        originalSubjects: [],
        subjects: [],
        tags: [],
        search: ""
      },
      testName: "",
      sectionName: "",
      howToHandlePassages: "doNotNeedPassage",
      question: {
        type: "default",
        id: null,
        content: "",
        order: null,
        tag_ids: [],
        options: [
          { letter: "A", number: 1, title: "" },
          { letter: "B", number: 2, title: "" },
          { letter: "C", number: 3, title: "" },
          { letter: "D", number: 4, title: "" }
        ],
        passage_id: null,
        answer: "",
        exp: ""
      },
      passages: [],
      passage: {
        title: "",
        intro: "",
        content: "",
        below_content: ""
      },
      tags: []
    };
  },
  // async created() {
  //   const { full_name, subjects } = await testsApi.getTest(
  //     this.$route.params.testType,
  //     this.$route.params.testTypeId
  //   );
  //   this.testName = full_name;
  //   this.sectionName = subjects.find(
  //     subject => String(subject.id) === this.$route.params.sectionId
  //   ).name;

  //   const question = await testsApi.getQuestion(
  //     this.$route.params.testType,
  //     this.$route.params.questionId
  //   );
  //   this.question = {
  //     ...this.question,
  //     ...question,
  //     options:
  //       question.options.length === 0
  //         ? this.question.options
  //         : question.options,
  //     tag_ids: question.tags.map(({ id }) => id),
  //     answer: this.getCorrectAnswer(question.answers),
  //     passage_id: question.passage ? question.passage.id : null,
  //     type: question.type === "math_range" ? "math" : question.type,
  //     is_aih: Boolean(question.is_aih),
  //     is_ais: Boolean(question.is_ais),
  //     is_coe: Boolean(question.is_coe),
  //     is_eoi: Boolean(question.is_eoi),
  //     is_hoa: Boolean(question.is_hoa),
  //     is_psada: Boolean(question.is_psada),
  //     is_ptam: Boolean(question.is_ptam),
  //     is_sec: Boolean(question.is_sec),
  //     is_wic: Boolean(question.is_wic)
  //   };

  //   if (this.question.passage_id !== null) {
  //     this.howToHandlePassages = "chooseExistPassage";
  //   }

  //   this.passages = await testsApi.getSubjectPassages(
  //     this.$route.params.testType,
  //     this.$route.params.sectionId
  //   );
  //   this.getTags();
  // },
  methods: {
    setSearch(value) {
      // this.$router.push({
      //   ...this.$route,
      //   query: { ...this.$route.query, ...value }
      // });
    },
    getCorrectAnswer(answers) {
      let cAnswer = "";
      answers.forEach((item, index) => {
        if (item.answers !== null) {
          if (index !== 0) {
            cAnswer += "," + this.getCAnswer(item.answers);
          } else {
            cAnswer = this.getCAnswer(item.answers);
          }
        }
      });
      return cAnswer;
    },
    getCAnswer(answers) {
      let answer = "";
      answers.forEach((item, index) => {
        if (item !== null) {
          if (index !== 0) {
            answer += "," + item;
          } else {
            answer = item;
          }
        }
      });
      return answer;
    },
    setQuestion(value) {
      this.question = { ...this.question, ...value };
    },
    setPassage(passage) {
      this.passage = { ...this.passage, ...passage };
    },
    async createPassage() {
      try {
        const passage = await testsApi.createSubjectPassage(
          this.$route.params.testType,
          this.$route.params.sectionId,
          this.passage
        );
        return passage.id;
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    getQuestionType() {
      if (this.question.type === "default") return "default";
      if (
        this.question.answer.indexOf("<") !== -1 ||
        this.question.answer.indexOf(">") !== -1 ||
        this.question.answer.indexOf("=") !== -1
      ) {
        return "math_range";
      }
      return "math";
    },
    getAnswer() {
      if (this.question.type === "default") {
        if (this.question.answer.indexOf(",") === -1) {
          return [{ answers: [this.question.answer] }];
        } else {
          return [{ answers: this.question.answer.split(",") }];
        }
      }
      return [{ answers: [this.question.answer] }];
    },
    async handleQuestion() {
      let questionContent = {};
      switch (this.$route.params.testType.toUpperCase()) {
        case "SAT":
          questionContent = {
            ...this.question,
            type: this.getQuestionType(),
            answers: this.getAnswer(),
            options:
              this.question.type === "default" ? this.question.options : [],
            is_aih: Number(this.question.is_aih),
            is_ais: Number(this.question.is_ais),
            is_coe: Number(this.question.is_coe),
            is_eoi: Number(this.question.is_eoi),
            is_hoa: Number(this.question.is_hoa),
            is_psada: Number(this.question.is_psada),
            is_ptam: Number(this.question.is_ptam),
            is_sec: Number(this.question.is_sec),
            is_wic: Number(this.question.is_wic)
          };
          break;
        case "ACT":
          questionContent = {
            ...this.question,
            type: this.getQuestionType(),
            answers: this.getAnswer(),
            options: this.question.options
          };
          break;
      }
      try {
        if (this.howToHandlePassages === "createNewPassage") {
          const passage_id = await this.createPassage();
          questionContent.passage_id = passage_id;
        } else if (this.howToHandlePassages === "doNotNeedPassage") {
          questionContent.passage_id = 0;
        }

        await testsApi.updateQuestion(
          this.$route.params.testType,
          this.$route.params.questionId,
          questionContent
        );
        this.$router.push({
          path: `/tests/${this.$route.params.testType}/test/${this.$route.params.testTypeId}/section/${this.$route.params.sectionId}/question`
        });
        if (this.isEditMode) {
          this.$message.success(this.$t("message.update_success"));
        } else {
          this.$message.success(this.$t("message.create_success"));
        }
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    changePassageType(type) {
      this.howToHandlePassages = type;
      this.passage = {
        title: "",
        intro: "",
        content: "",
        below_content: ""
      };
    },
    getCurrenctPassage() {
      return this.passages.find(({ id }) => id === this.question.passage_id);
    },
    needToPassage() {
      return ["english", "reading", "writing", "science", "essay"].includes(
        this.sectionName
      );
    },
    async getTags() {
      const { tags } = await tagApi.fetchTags();
      this.tags = tags;
    },
    convertHtmlToText(html) {
      let container = document.createElement("div");
      container.innerHTML = html;
      return container.innerText;
    }
  }
};
</script>
<style scoped>
.ivy-question >>> p {
  margin-bottom: 0;
}
</style>
