var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'Questions'
          }}},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Question"))+" ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2),_c('h3',[_vm._v(_vm._s(_vm.pageTitle))]),_c('hr',{staticClass:"separate-line"}),_c('div',{staticClass:"search"},[_c('el-form',{ref:"form",attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"Type"}},[_c('el-select',{model:{value:(_vm.activeType),callback:function ($$v) {_vm.activeType=$$v},expression:"activeType"}},_vm._l((_vm.typeOptions),function(item){return _c('el-option',{key:item.obj,attrs:{"label":item.name,"value":item.obj}})}),1)],1),_vm._l((_vm.subjectArr),function(subject,index){return _c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeType === subject.name),expression:"activeType === subject.name"}],key:index},[_c('el-radio-group',{model:{value:(_vm.questionType),callback:function ($$v) {_vm.questionType=$$v},expression:"questionType"}},_vm._l((subject.subjects),function(toeflSubject){return _c('el-radio-button',{key:toeflSubject.id,attrs:{"label":toeflSubject.id}},[_vm._v(" "+_vm._s(toeflSubject.title)+" ")])}),1)],1)})],2),_c('hr',{staticClass:"separate-line"})],1),[_c('el-form',{ref:"form",attrs:{"label-width":"100px","model":_vm.question}},[_c('div',{staticStyle:{"padding":"0 20px","border":"1px solid #dcdfe6"}},[_c('el-row',[_c('el-col',{staticClass:"p-3",staticStyle:{"height":"700px","overflow":"auto"},attrs:{"span":12}},[_c('h4',[_vm._v("Passage")]),_c('hr'),_c('div',{staticStyle:{"padding-right":"20px"}},[_c('div',{staticStyle:{"text-align":"center","margin-bottom":"20px"}},[_vm._v(" 請問這個題目需要 Passage 嗎？ "),_c('el-button',{staticStyle:{"margin":"0px 4px"},attrs:{"type":_vm.howToHandlePassages === 'doNotNeedPassage'
                      ? 'primary'
                      : ''},on:{"click":function($event){return _vm.changePassageType('doNotNeedPassage')}}},[_vm._v(" 不需要 ")]),_c('el-button',{staticStyle:{"margin":"0px 4px"},attrs:{"type":_vm.howToHandlePassages === 'chooseExistPassage'
                      ? 'primary'
                      : ''},on:{"click":function($event){return _vm.changePassageType('chooseExistPassage')}}},[_vm._v(" 需要，直接選擇現有的 Passage ")]),_c('el-button',{staticStyle:{"margin":"0px 4px"},attrs:{"type":_vm.howToHandlePassages === 'createNewPassage'
                      ? 'primary'
                      : ''},on:{"click":function($event){return _vm.changePassageType('createNewPassage')}}},[_vm._v(" 需要，並新增一個新的 Passage ")])],1),(_vm.howToHandlePassages === 'chooseExistPassage')?_c('el-form-item',{attrs:{"label":"Passage"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":"","placeholder":"Select"},model:{value:(_vm.question.passage_id),callback:function ($$v) {_vm.$set(_vm.question, "passage_id", $$v)},expression:"question.passage_id"}},_vm._l((_vm.passages),function(passage){return _c('el-option',{key:passage.id,attrs:{"value":passage.id,"label":_vm.convertHtmlToText(passage.content)}},[_c('div',{domProps:{"innerHTML":_vm._s(passage.content)}})])}),1)],1):_vm._e(),(_vm.howToHandlePassages === 'createNewPassage')?_c('div',[_c('PassageForm',{attrs:{"passage":_vm.passage,"testType":_vm.$route.params.testType,"sectionName":_vm.sectionName},on:{"setPassage":_vm.setPassage}})],1):_vm._e(),_c('ViewPassage',{key:_vm.question,attrs:{"passage":_vm.getCurrenctPassage(),"type":_vm.$route.params.testType,"section":_vm.sectionName}})],1)]),_c('el-col',{staticClass:"ivy-question",attrs:{"span":12}},[_c('QuestionForm',{attrs:{"isEditMode":true,"question":_vm.question,"tags":_vm.tags,"testType":_vm.$route.params.testType,"sectionName":_vm.sectionName},on:{"setQuestion":_vm.setQuestion}})],1)],1)],1),_c('hr',{staticClass:"separate-line"})])],_c('div',{staticStyle:{"text-align":"center","margin-bottom":"20px"}},[_c('el-button',{staticStyle:{"margin":"20px"},attrs:{"type":"primary"},on:{"click":_vm.handleQuestion}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }